.amplify-tabs {
  display: none;
}

:root, [data-amplify-theme]  {
  --amplify-fonts-default-static: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI',
    Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
  --amplify-fonts-default-variable: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI',
    Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
  --amplify-components-button-primary-background-color: #035da1;
  --amplify-components-button-primary-hover-background-color: #023db1;
  --amplify-components-button-link-color: #035da1;
}
